// src/animationData.js
import pandaAnimation from './assets/panda_riding_a_unicycle.riv';
import arcaneWallpaper from './assets/arcane_-_interactive_wallpaper.riv';
import catButton from './assets/cat_button.riv';
import catEyes from './assets/cat_eyes.riv';
import heroTest from './assets/hero_test.riv';
import wa from './assets/wa.riv';

export const fetchAnimations = () => {
  // This function simulates an API call
  return Promise.resolve([
    { 
      id: 1, 
      name: 'Panda Riding a Unicycle', 
      src: pandaAnimation, 
      alt: 'Panda Animation', 
      price: 9.99 
    },
    { 
      id: 2, 
      name: 'Arcane Interactive Wallpaper', 
      src: arcaneWallpaper, 
      alt: 'Arcane Wallpaper Animation', 
      price: 14.99 
    },
    { 
      id: 3, 
      name: 'Cat Button', 
      src: catButton, 
      alt: 'Cat Button Animation', 
      price: 6.99 
    },
    { 
      id: 4, 
      name: 'Cat Eyes', 
      src: catEyes, 
      alt: 'Cat Eyes Animation', 
      price: 7.99 
    },
    { 
      id: 5, 
      name: 'Hero Test', 
      src: heroTest, 
      alt: 'Hero Test Animation', 
      price: 8.99 
    },
    { 
      id: 6, 
      name: 'WA Animation', 
      src: wa, 
      alt: 'WA Animation', 
      price: 5.99 
    },
  ]);
};