import React from "react";
import { FaArrowLeft, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";

const CartPage = ({ cartItems, removeFromCart, setShowCheckout }) => {
  const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);

  return (
    <div className="min-h-screen bg-[#1a1a1a] text-white p-6">
      <div className="max-w-4xl mx-auto">
        <Link to="/">
          <button className="flex items-center text-[#00c896] hover:text-[#00a07a] mb-6">
            <FaArrowLeft className="mr-2" />
            Back to Shop
          </button>
        </Link>

        <h1 className="text-3xl font-bold mb-6">Your Cart</h1>
        {cartItems.length === 0 ? (
          <p className="text-xl text-gray-400">Your cart is empty.</p>
        ) : (
          <>
            <ul className="space-y-4 mb-8">
              {cartItems.map((item, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center bg-[#2a2a2a] p-4 rounded"
                >
                  <div>
                    <h3 className="font-semibold">{item.name}</h3>
                    <p className="text-[#00c896]">${item.price.toFixed(2)}</p>
                  </div>
                  <button
                    onClick={() => removeFromCart(item)}
                    className="text-[#ff4d4d] hover:text-red-700 p-2"
                    aria-label="Remove item"
                  >
                    <FaTrash />
                  </button>
                </li>
              ))}
            </ul>
            <div className="bg-[#2a2a2a] p-6 rounded">
              <p className="text-2xl font-bold mb-4">
                Total: ${totalPrice.toFixed(2)}
              </p>
              <button
                onClick={setShowCheckout}
                className="w-full bg-[#00c896] text-[#0a0a0a] py-3 px-4 rounded hover:bg-[#00a07a] font-semibold text-lg"
              >
                Proceed to Checkout
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CartPage;
