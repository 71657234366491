import React from 'react';
import Logo from '../common/Logo';
import Newsletter from '../common/Newsletter';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import '../../styles/footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section brand">
          <Logo />
          <h3>RiveKit</h3>
          <p>Empowering designers and developers with stunning Rive animations.</p>
        </div>
        <div className="footer-section links">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="#animations">Animations</a></li>
            <li><a href="#pricing">Pricing</a></li>
            <li><a href="#">Documentation</a></li>
            <li><a href="#">Support</a></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h4>Contact Us</h4>
          <p>Email: support@rivekit.com</p>
          <p>Phone: (555) 123-4567</p>
        </div>
        <div className="footer-section social">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <a href="#" className="social-icon"><FaFacebookF /></a>
            <a href="#" className="social-icon"><FaTwitter /></a>
            <a href="#" className="social-icon"><FaInstagram /></a>
            <a href="#" className="social-icon"><FaLinkedinIn /></a>
          </div>
        </div>
      </div>
      {/* <div className="newsletter-wrapper">
        <Newsletter />
      </div> */}
      <div className="footer-bottom">
        <p>&copy; 2024 RiveKit. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;