import React from "react";
import RiveAnimation from "../common/RiveAnimation";
import { FaShoppingCart, FaEye, FaHeart, FaRegHeart } from "react-icons/fa";

const AnimationCard = ({
  animation,
  setSelectedAnimation,
  addToCart,
  toggleWishlist,
  isInWishlist,
}) => {
  if (!animation) return null;

  const {
    name = "Untitled Animation",
    description = "No description available",
    price = 0,
    src = "",
    alt = "Animation preview",
  } = animation;

  return (
    <div className="bg-[#1E1E1E] rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-2xl hover:scale-105 flex flex-col h-full">
      <div className="relative w-full h-48 bg-[#2A2A2A]">
        <RiveAnimation src={src} alt={alt} className="w-full h-full" />
        <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-50 transition-opacity duration-300 flex items-center justify-center opacity-0 hover:opacity-100">
          <button
            onClick={() => setSelectedAnimation(animation)}
            className="bg-[#00c896] text-white p-2 rounded-full mr-3 hover:bg-[#00a07a] transition-all duration-300 transform hover:scale-110"
            aria-label="Quick view"
          >
            <FaEye size={20} />
          </button>
          <button
            onClick={() => addToCart(animation)}
            className="bg-[#00c896] text-white p-2 rounded-full hover:bg-[#00a07a] transition-all duration-300 transform hover:scale-110"
            aria-label="Add to cart"
          >
            <FaShoppingCart size={20} />
          </button>
          <button
            onClick={() => toggleWishlist(animation)}
            className="bg-transparent text-white p-2 rounded-full hover:bg-[#00a07a] transition-all duration-300 transform hover:scale-110"
            aria-label={
              isInWishlist ? "Remove from wishlist" : "Add to wishlist"
            }
          >
            {isInWishlist ? <FaHeart size={20} /> : <FaRegHeart size={20} />}
          </button>
        </div>
      </div>
      <div className="p-4 flex-grow flex flex-col justify-between">
        <div>
          <h3 className="text-lg font-semibold text-white mb-1 truncate">
            {name}
          </h3>
          <p className="text-gray-400 text-sm line-clamp-2">{description}</p>
        </div>
        <div className="flex justify-between items-center mt-4">
          <p className="text-[#00c896] font-bold text-xl">
            ${typeof price === "number" ? price.toFixed(2) : "0.00"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AnimationCard;
