import React from "react";
import { FaDownload } from "react-icons/fa";

const PurchaseHistory = () => {
  // Mock data for purchase history
  const purchases = [
    {
      id: 1,
      title: "Happy Bounce",
      designer: "Jane Doe",
      date: "2023-05-15",
      price: 19.99,
      thumbnailUrl: "https://example.com/thumb1.jpg",
    },
    {
      id: 2,
      title: "Smooth Transition",
      designer: "John Smith",
      date: "2023-05-10",
      price: 24.99,
      thumbnailUrl: "https://example.com/thumb2.jpg",
    },
    {
      id: 3,
      title: "Playful Icons",
      designer: "Alice Johnson",
      date: "2023-05-05",
      price: 14.99,
      thumbnailUrl: "https://example.com/thumb3.jpg",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Purchase History</h1>
      <div className="bg-gray-900 text-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-800">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Animation
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Designer
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Price
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-900 divide-y divide-gray-700">
            {purchases.map((purchase) => (
              <tr key={purchase.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full object-cover"
                        src={purchase.thumbnailUrl}
                        alt={purchase.title}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-white">
                        {purchase.title}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-400">
                    {purchase.designer}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-400">{purchase.date}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
                  ${purchase.price.toFixed(2)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button className="text-[#00c896] hover:text-[#00a07a]">
                    <FaDownload className="inline mr-1" /> Download
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PurchaseHistory;
