import React from "react";

const categories = [
  "All",
  "Characters",
  "Icons",
  "Illustrations",
  "UI Elements",
];

const CategoryFilter = ({ selectedCategory, setSelectedCategory }) => {
  return (
    <div className="flex flex-wrap justify-center gap-2 my-4">
      {categories.map((category) => (
        <button
          key={category}
          onClick={() => setSelectedCategory(category)}
          className={`px-4 py-2 rounded-full ${
            selectedCategory === category
              ? "bg-[#00c896] text-[#0a0a0a]"
              : "bg-[#1a1a1a] text-white hover:bg-[#2a2a2a]"
          }`}
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default CategoryFilter;
