import React from "react";
import RiveAnimation from "../common/RiveAnimation";
import { FaShoppingCart, FaDownload, FaTimes } from "react-icons/fa";

const AnimationModal = ({ animation, closeModal, addToCart }) => {
  if (!animation) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-[#1E1E1E] rounded-lg w-full max-w-5xl overflow-hidden shadow-2xl flex h-[80vh]">
        {/* Left side - Rive Animation */}
        <div className="w-2/3 bg-[#2A2A2A] flex items-center justify-center p-4">
          <div className="w-full h-full relative flex items-center justify-center">
            <RiveAnimation
              src={animation.src}
              alt={animation.alt}
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>

        {/* Right side - Info Panel */}
        <div className="w-1/3 p-6 flex flex-col bg-[#1E1E1E] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-white">{animation.name}</h2>
            <button
              onClick={closeModal}
              className="text-gray-400 hover:text-white transition-colors duration-200"
              aria-label="Close modal"
            >
              <FaTimes size={20} />
            </button>
          </div>

          <p className="text-3xl font-bold text-[#00c896] mb-6">
            ${animation.price.toFixed(2)}
          </p>

          <button
            onClick={() => addToCart(animation)}
            className="w-full flex items-center justify-center bg-[#00c896] text-white px-4 py-2 rounded-md hover:bg-[#00a07a] transition-colors duration-300 mb-3"
          >
            <FaShoppingCart className="mr-2" />
            Add to Cart
          </button>

          <button className="w-full flex items-center justify-center bg-[#3F3F3F] text-white px-4 py-2 rounded-md hover:bg-[#4F4F4F] transition-colors duration-300">
            <FaDownload className="mr-2" />
            Download
          </button>

          <div className="mt-6 bg-[#2A2A2A] rounded-md p-4">
            <h3 className="text-lg font-semibold text-white mb-2">
              Description
            </h3>
            <p className="text-gray-300 text-sm">
              {animation.description ||
                "No description available for this animation."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimationModal;
