import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-[#00c896]">
      <button
        className="flex justify-between items-center w-full py-5 px-3 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-[#ffffff] font-medium">{question}</span>
        {isOpen ? (
          <FaChevronUp className="text-[#00c896]" />
        ) : (
          <FaChevronDown className="text-[#00c896]" />
        )}
      </button>
      {isOpen && (
        <div className="pb-5 px-3">
          <p className="text-[#ffffff]">{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    {
      question: "What file formats do you support for animations?",
      answer:
        "We support both Lottie (JSON) and Rive file formats for animations. These formats are widely used in the industry and provide excellent performance and flexibility.",
    },
    {
      question: "How do I upload my animation to the marketplace?",
      answer:
        "To upload your animation, go to your designer dashboard and click on the 'Upload Animation' button. Fill out the required information, set your price, and upload your Lottie or Rive file. Our team will review your submission and approve it for the marketplace.",
    },
    {
      question: "What are the pricing options for animations?",
      answer:
        "As a designer, you have the flexibility to set your own prices for your animations. We recommend considering factors such as complexity, uniqueness, and market demand when pricing your work. Buyers can purchase individual animations or opt for subscription plans for bulk access.",
    },
    {
      question: "How do royalties and payments work for designers?",
      answer:
        "Designers earn a 70% royalty on each sale of their animations. Payments are processed monthly for all sales accumulated in the previous month, provided you've reached the minimum payout threshold of $50. We support various payout methods including PayPal and direct bank transfers.",
    },
    {
      question: "Can I use the animations I purchase for commercial projects?",
      answer:
        "Yes, all animations purchased on our platform come with a standard commercial license. This allows you to use the animations in your projects, including commercial ones. However, reselling or redistributing the animations as-is is not permitted.",
    },
    {
      question: "How can I become a featured designer on the platform?",
      answer:
        "Featured designers are selected based on the quality and popularity of their work, as well as their overall contribution to the community. Consistently uploading high-quality animations, maintaining good sales, and receiving positive reviews will increase your chances of becoming featured.",
    },
    {
      question: "What if I'm not satisfied with an animation I've purchased?",
      answer:
        "We offer a 30-day money-back guarantee on all purchases. If you're not satisfied with an animation, you can request a refund within 30 days of purchase. Please note that repeated refund requests may be subject to review to prevent abuse.",
    },
    {
      question: "How can I report copyright infringement or plagiarism?",
      answer:
        "We take intellectual property rights very seriously. If you believe that an animation on our platform infringes on your copyright, please contact our support team immediately with details of the infringement. We will investigate and take appropriate action, which may include removing the infringing content.",
    },
  ];

  return (
    <div className="min-h-screen bg-[#0a0a0a] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-[#00c896] mb-8">
          Frequently Asked Questions
        </h2>
        <div className="bg-[#1a1a1a] rounded-lg shadow-lg">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
