import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUpload, FaTags } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";

const UploadAnimation = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    category: "",
    tags: "",
    file: null,
  });
  const [previewUrl, setPreviewUrl] = useState("");
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.name.endsWith(".riv")) {
      setFormData({ ...formData, file: file });
      setPreviewUrl(URL.createObjectURL(file));
    } else {
      alert("Please upload a .riv file");
      e.target.value = null;
      setFormData({ ...formData, file: null });
      setPreviewUrl("");
    }
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.title.trim()) formErrors.title = "Title is required";
    if (!formData.description.trim())
      formErrors.description = "Description is required";
    if (!formData.price || isNaN(formData.price))
      formErrors.price = "Valid price is required";
    if (!formData.category) formErrors.category = "Category is required";
    if (!formData.file) formErrors.file = "Rive animation file is required";
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("price", formData.price);
    formDataToSend.append("category", formData.category);
    formDataToSend.append("tags", formData.tags);
    if (formData.file) {
      formDataToSend.append("file", formData.file);
    }

    // Debug: Log FormData contents
    for (let [key, value] of formDataToSend.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const token = await getAccessTokenSilently();

      const response = await fetch("http://localhost:3001/api/assets", {
        method: "POST",
        body: formDataToSend,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Response error:", response.status, errorText);
        throw new Error(`HTTP error! status: ${response.status}. ${errorText}`);
      }

      const result = await response.json();
      console.log("Animation uploaded successfully:", result);
      alert("Animation uploaded successfully!");
      navigate("/designer-profile");
    } catch (error) {
      console.error("Error uploading animation:", error);
      alert(error.message || "Error uploading animation. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-[#0a0a0a] py-8 px-4 sm:px-6 lg:px-8 flex justify-center items-center">
      <div className="max-w-2xl w-full space-y-8 bg-[#1a1a1a] p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-[#00c896] text-center mb-6">
          Upload New Animation
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-[#ffffff]"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 rounded-md bg-[#0a0a0a] text-[#ffffff] border border-[#00c896] placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#00c896] focus:border-transparent"
            />
            {errors.title && (
              <p className="mt-2 text-sm text-[#ff4d4d]">{errors.title}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-[#ffffff]"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows="3"
              className="mt-1 block w-full px-3 py-2 rounded-md bg-[#0a0a0a] text-[#ffffff] border border-[#00c896] placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#00c896] focus:border-transparent"
            ></textarea>
            {errors.description && (
              <p className="mt-2 text-sm text-[#ff4d4d]">
                {errors.description}
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="price"
              className="block text-sm font-medium text-[#ffffff]"
            >
              Price ($)
            </label>
            <input
              type="number"
              id="price"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              step="0.01"
              min="0"
              className="mt-1 block w-full px-3 py-2 rounded-md bg-[#0a0a0a] text-[#ffffff] border border-[#00c896] placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#00c896] focus:border-transparent"
            />
            {errors.price && (
              <p className="mt-2 text-sm text-[#ff4d4d]">{errors.price}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="category"
              className="block text-sm font-medium text-[#ffffff]"
            >
              Category
            </label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 rounded-md bg-[#0a0a0a] text-[#ffffff] border border-[#00c896] focus:outline-none focus:ring-2 focus:ring-[#00c896] focus:border-transparent"
            >
              <option value="">Select a category</option>
              <option value="characters">Characters</option>
              <option value="icons">Icons</option>
              <option value="illustrations">Illustrations</option>
              <option value="ui">UI Elements</option>
            </select>
            {errors.category && (
              <p className="mt-2 text-sm text-[#ff4d4d]">{errors.category}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="tags"
              className="block text-sm font-medium text-[#ffffff]"
            >
              Tags
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-[#00c896] bg-[#0a0a0a] text-[#00c896]">
                <FaTags className="h-5 w-5" />
              </span>
              <input
                type="text"
                id="tags"
                name="tags"
                value={formData.tags}
                onChange={handleInputChange}
                className="flex-1 block w-full px-3 py-2 rounded-none rounded-r-md bg-[#0a0a0a] text-[#ffffff] border border-[#00c896] placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#00c896] focus:border-transparent"
                placeholder="Separate tags with commas"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-[#ffffff]">
              Upload Rive Animation File (.riv)
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-[#00c896] border-dashed rounded-md bg-[#0a0a0a]">
              <div className="space-y-1 text-center">
                <FaUpload className="mx-auto h-12 w-12 text-[#00c896]" />
                <div className="flex text-sm text-[#ffffff]">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-transparent rounded-md font-medium text-[#00c896] hover:text-[#ff4d4d] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#00c896]"
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileChange}
                      accept=".riv"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">
                  Rive (.riv) file up to 10MB
                </p>
              </div>
            </div>
            {errors.file && (
              <p className="mt-2 text-sm text-[#ff4d4d]">{errors.file}</p>
            )}
          </div>

          {previewUrl && (
            <div className="mt-6">
              <h3 className="text-lg font-medium text-[#ffffff] mb-2">
                File Selected
              </h3>
              <div className="border rounded-md p-4 bg-[#0a0a0a]">
                <p className="text-[#ffffff]">Rive animation file selected</p>
              </div>
            </div>
          )}

          <div className="mt-6">
            <button
              type="submit"
              className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-[#0a0a0a] bg-[#00c896] hover:bg-[#ff4d4d] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00c896]"
            >
              Upload Animation
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadAnimation;
