import React, { useState } from "react";

const CheckoutProcess = ({ cartItems, closeCheckout }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    country: "",
    zipCode: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process payment and order here
    console.log("Order submitted:", formData);
    closeCheckout();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-[#1a1a1a] rounded-lg p-8 max-w-2xl w-full text-white relative">
        <button
          onClick={closeCheckout}
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-2xl font-bold mb-4 text-[#00c896]">Checkout</h2>
        <div className="mb-4">
          <div className="flex justify-between mb-2">
            <span className={step >= 1 ? "text-[#00c896] font-semibold" : ""}>
              Shipping
            </span>
            <span className={step >= 2 ? "text-[#00c896] font-semibold" : ""}>
              Payment
            </span>
            <span className={step >= 3 ? "text-[#00c896] font-semibold" : ""}>
              Confirmation
            </span>
          </div>
          <div className="w-full bg-[#0a0a0a] rounded-full h-2.5">
            <div
              className="bg-[#00c896] h-2.5 rounded-full transition-all duration-300 ease-in-out"
              style={{ width: `${((step - 1) / 2) * 100}%` }}
            ></div>
          </div>
        </div>
        {step === 1 && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setStep(2);
            }}
            className="space-y-4"
          >
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Full Name"
              className="w-full p-2 border rounded bg-[#0a0a0a] text-white border-[#00c896]"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="w-full p-2 border rounded bg-[#0a0a0a] text-white border-[#00c896]"
              required
            />
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Address"
              className="w-full p-2 border rounded bg-[#0a0a0a] text-white border-[#00c896]"
              required
            />
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="City"
                className="w-full p-2 border rounded bg-[#0a0a0a] text-white border-[#00c896]"
                required
              />
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Country"
                className="w-full p-2 border rounded bg-[#0a0a0a] text-white border-[#00c896]"
                required
              />
            </div>
            <input
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              placeholder="Zip Code"
              className="w-full p-2 border rounded bg-[#0a0a0a] text-white border-[#00c896]"
              required
            />
            <button
              type="submit"
              className="w-full bg-[#00c896] text-[#0a0a0a] rounded-md py-2 px-4 hover:bg-[#00a07a]"
            >
              Next
            </button>
          </form>
        )}
        {step === 2 && (
          <div>
            <p className="mb-4">
              Payment details would go here. For this example, we'll skip the
              actual payment process.
            </p>
            <button
              onClick={() => setStep(3)}
              className="w-full bg-[#00c896] text-[#0a0a0a] rounded-md py-2 px-4 hover:bg-[#00a07a]"
            >
              Place Order
            </button>
          </div>
        )}
        {step === 3 && (
          <div>
            <p className="mb-4">
              Thank you for your order! Your animations will be available for
              download shortly.
            </p>
            <button
              onClick={closeCheckout}
              className="w-full bg-[#00c896] text-[#0a0a0a] rounded-md py-2 px-4 hover:bg-[#00a07a]"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutProcess;
