import React, { useState } from "react";
import {
  FaUser,
  FaEnvelope,
  FaCalendar,
  FaPencilAlt,
  FaSave,
} from "react-icons/fa";

const UserProfile = () => {
  // Dummy user data
  const [user, setUser] = useState({
    name: "John Doe",
    email: "john.doe@example.com",
    joinDate: "2023-01-15",
    bio: "Animation enthusiast and hobbyist designer.",
    location: "New York, USA",
    website: "https://johndoe.com",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState(user);

  // Dummy recent activity data
  const recentActivity = [
    {
      id: 1,
      type: "purchase",
      item: "Happy Bounce Animation",
      date: "2023-05-20",
    },
    {
      id: 2,
      type: "review",
      item: "Smooth Transition Effect",
      date: "2023-05-18",
    },
    { id: 3, type: "wishlist", item: "Playful Icons Set", date: "2023-05-15" },
  ];

  const handleEdit = () => {
    setIsEditing(true);
    setEditedUser(user);
  };

  const handleSave = () => {
    setUser(editedUser);
    setIsEditing(false);
    // Here you would typically send an API request to update the user's profile
  };

  const handleChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-white">User Profile</h1>
      <div className="bg-[#1E1E1E] shadow-lg rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <div className="bg-[#00c896] rounded-full p-3 mr-4">
                <FaUser className="text-white text-2xl" />
              </div>
              <div>
                <h2 className="text-2xl font-semibold text-white">
                  {user.name}
                </h2>
                <p className="text-gray-400">{user.email}</p>
              </div>
            </div>
            {!isEditing ? (
              <button
                onClick={handleEdit}
                className="bg-[#00c896] text-white px-4 py-2 rounded hover:bg-[#00a07a] transition-colors duration-300"
              >
                <FaPencilAlt className="inline-block mr-2" />
                Edit Profile
              </button>
            ) : (
              <button
                onClick={handleSave}
                className="bg-[#00c896] text-white px-4 py-2 rounded hover:bg-[#00a07a] transition-colors duration-300"
              >
                <FaSave className="inline-block mr-2" />
                Save Changes
              </button>
            )}
          </div>
          <div className="mt-6">
            <div className="mb-4">
              <label
                className="block text-gray-400 text-sm font-bold mb-2"
                htmlFor="bio"
              >
                Bio
              </label>
              {isEditing ? (
                <textarea
                  id="bio"
                  name="bio"
                  value={editedUser.bio}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-300 bg-[#2A2A2A] leading-tight focus:outline-none focus:shadow-outline"
                  rows="3"
                />
              ) : (
                <p className="text-gray-400">{user.bio}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-400 text-sm font-bold mb-2"
                htmlFor="location"
              >
                Location
              </label>
              {isEditing ? (
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={editedUser.location}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-300 bg-[#2A2A2A] leading-tight focus:outline-none focus:shadow-outline"
                />
              ) : (
                <p className="text-gray-400">{user.location}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-400 text-sm font-bold mb-2"
                htmlFor="website"
              >
                Website
              </label>
              {isEditing ? (
                <input
                  type="url"
                  id="website"
                  name="website"
                  value={editedUser.website}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-300 bg-[#2A2A2A] leading-tight focus:outline-none focus:shadow-outline"
                />
              ) : (
                <a
                  href={user.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#00c896] hover:underline"
                >
                  {user.website}
                </a>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-gray-400 text-sm font-bold mb-2">
                Member Since
              </label>
              <p className="text-gray-400">
                <FaCalendar className="inline-block mr-2" />
                {user.joinDate}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4 text-white">Recent Activity</h2>
        <div className="bg-[#1E1E1E] shadow-lg rounded-lg overflow-hidden">
          <ul className="divide-y divide-gray-700">
            {recentActivity.map((activity) => (
              <li
                key={activity.id}
                className="p-4 hover:bg-[#2A2A2A] transition-colors duration-300"
              >
                <div className="flex justify-between">
                  <span className="font-medium text-white">
                    {activity.item}
                  </span>
                  <span className="text-gray-500">{activity.date}</span>
                </div>
                <p className="text-sm text-gray-400 mt-1">
                  {activity.type === "purchase" && "Purchased"}
                  {activity.type === "review" && "Reviewed"}
                  {activity.type === "wishlist" && "Added to wishlist"}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
