import React from "react";
import AnimationCard from "./animation_card";

const AnimationGrid = ({
  animations,
  setSelectedAnimation,
  addToCart,
  toggleWishlist,
  wishlistItems,
}) => {
  return (
    <div className="animation-grid masonry">
      {animations.map((animation) => (
        <AnimationCard
          key={animation.id}
          animation={animation}
          setSelectedAnimation={setSelectedAnimation}
          addToCart={addToCart}
          toggleWishlist={toggleWishlist}
          isInWishlist={wishlistItems.some((item) => item.id === animation.id)}
        />
      ))}
    </div>
  );
};

export default AnimationGrid;
