import React, { useState } from "react";
import { FaTrophy, FaStar, FaDownload } from "react-icons/fa";

const LeaderboardPage = () => {
  const [sortBy, setSortBy] = useState("sales");

  // Mock data for leaderboard
  const designers = [
    {
      id: 1,
      name: "Jane Doe",
      avatarUrl: "https://example.com/avatar1.jpg",
      sales: 1250,
      rating: 4.8,
      downloads: 5000,
    },
    {
      id: 2,
      name: "John Smith",
      avatarUrl: "https://example.com/avatar2.jpg",
      sales: 980,
      rating: 4.9,
      downloads: 4200,
    },
    {
      id: 3,
      name: "Alice Johnson",
      avatarUrl: "https://example.com/avatar3.jpg",
      sales: 850,
      rating: 4.7,
      downloads: 3800,
    },
    {
      id: 4,
      name: "Bob Williams",
      avatarUrl: "https://example.com/avatar4.jpg",
      sales: 720,
      rating: 4.6,
      downloads: 3200,
    },
    {
      id: 5,
      name: "Emma Brown",
      avatarUrl: "https://example.com/avatar5.jpg",
      sales: 650,
      rating: 4.8,
      downloads: 2900,
    },
  ];

  const sortedDesigners = [...designers].sort((a, b) => {
    if (sortBy === "sales") return b.sales - a.sales;
    if (sortBy === "rating") return b.rating - a.rating;
    return b.downloads - a.downloads;
  });

  return (
    <div className="container mx-auto px-4 py-8 bg-[#0a0a0a]">
      <h1 className="text-3xl font-bold text-[#00c896] mb-6">
        Designer Leaderboard
      </h1>

      <div className="mb-6">
        <label htmlFor="sortBy" className="text-[#ffffff] mr-2">
          Sort by:
        </label>
        <select
          id="sortBy"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="border border-[#00c896] rounded px-2 py-1 bg-[#1a1a1a] text-[#ffffff] focus:outline-none focus:ring-2 focus:ring-[#00c896]"
        >
          <option value="sales">Total Sales</option>
          <option value="rating">Average Rating</option>
          <option value="downloads">Total Downloads</option>
        </select>
      </div>

      <div className="bg-[#1a1a1a] shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-[#0a0a0a] text-[#ffffff]">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Rank
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Designer
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Sales
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Rating
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Downloads
              </th>
            </tr>
          </thead>
          <tbody className="bg-[#0a0a0a] divide-y divide-gray-200 text-[#ffffff]">
            {sortedDesigners.map((designer, index) => (
              <tr key={designer.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {index === 0 && (
                    <FaTrophy className="text-[#ff4d4d] inline mr-1" />
                  )}
                  {index + 1}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full border-2 border-[#00c896]"
                        src={designer.avatarUrl}
                        alt={designer.name}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium">{designer.name}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm">{designer.sales}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm flex items-center">
                    <FaStar className="text-[#ff4d4d] mr-1" />
                    {designer.rating.toFixed(1)}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm flex items-center">
                  <FaDownload className="text-[#00c896] mr-1" />
                  {designer.downloads}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaderboardPage;
