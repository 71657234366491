import React from "react";
import { FaDownload, FaDollarSign, FaStar } from "react-icons/fa";

const DesignerDashboard = () => {
  // Mock data for designer dashboard
  const stats = {
    totalSales: 1250,
    totalRevenue: 18750.5,
    averageRating: 4.8,
  };

  const recentSales = [
    { id: 1, title: "Happy Bounce", date: "2023-05-15", price: 19.99 },
    { id: 2, title: "Smooth Transition", date: "2023-05-14", price: 24.99 },
    { id: 3, title: "Playful Icons", date: "2023-05-13", price: 14.99 },
  ];

  const topAnimations = [
    { id: 1, title: "Happy Bounce", downloads: 500, revenue: 9995 },
    { id: 2, title: "Smooth Transition", downloads: 450, revenue: 11245.5 },
    { id: 3, title: "Playful Icons", downloads: 300, revenue: 4497 },
  ];

  return (
    <div className="min-h-screen bg-[#0a0a0a] py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto space-y-8">
        <h1 className="text-3xl font-bold text-[#00c896] text-center mb-6">
          Designer Dashboard
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-[#1a1a1a] p-6 rounded-lg shadow-md">
            <div className="flex items-center justify-between">
              <div className="text-xl font-semibold text-[#ffffff]">
                Total Sales
              </div>
              <FaDownload className="text-blue-500 text-2xl" />
            </div>
            <div className="text-3xl font-bold text-[#ffffff] mt-2">
              {stats.totalSales}
            </div>
          </div>
          <div className="bg-[#1a1a1a] p-6 rounded-lg shadow-md">
            <div className="flex items-center justify-between">
              <div className="text-xl font-semibold text-[#ffffff]">
                Total Revenue
              </div>
              <FaDollarSign className="text-green-500 text-2xl" />
            </div>
            <div className="text-3xl font-bold text-[#ffffff] mt-2">
              ${stats.totalRevenue.toFixed(2)}
            </div>
          </div>
          <div className="bg-[#1a1a1a] p-6 rounded-lg shadow-md">
            <div className="flex items-center justify-between">
              <div className="text-xl font-semibold text-[#ffffff]">
                Average Rating
              </div>
              <FaStar className="text-yellow-400 text-2xl" />
            </div>
            <div className="text-3xl font-bold text-[#ffffff] mt-2">
              {stats.averageRating.toFixed(1)}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-[#1a1a1a] p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-[#ffffff] mb-4">
              Recent Sales
            </h2>
            <ul className="divide-y divide-gray-600">
              {recentSales.map((sale) => (
                <li key={sale.id} className="py-3">
                  <div className="flex justify-between text-[#ffffff]">
                    <span className="font-medium">{sale.title}</span>
                    <span>${sale.price.toFixed(2)}</span>
                  </div>
                  <div className="text-sm text-gray-400">{sale.date}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-[#1a1a1a] p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-[#ffffff] mb-4">
              Top Performing Animations
            </h2>
            <ul className="divide-y divide-gray-600">
              {topAnimations.map((animation) => (
                <li key={animation.id} className="py-3">
                  <div className="flex justify-between text-[#ffffff]">
                    <span className="font-medium">{animation.title}</span>
                    <span>{animation.downloads} downloads</span>
                  </div>
                  <div className="text-sm text-gray-400">
                    Revenue: ${animation.revenue.toFixed(2)}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignerDashboard;
