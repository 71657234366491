import React from "react";
import { FaStar, FaDownload, FaDollarSign } from "react-icons/fa";

const DesignerProfilePage = () => {
  // Mock data for the designer
  const designer = {
    name: "Jane Doe",
    avatarUrl: "https://example.com/avatar.jpg",
    location: "New York, USA",
    bio: "Passionate animator with 10 years of experience in motion graphics.",
    averageRating: 4.8,
    totalReviews: 120,
    totalDownloads: 5000,
    totalSales: 450,
  };

  // Mock data for animations
  const animations = [
    {
      id: 1,
      title: "Happy Bounce",
      thumbnailUrl: "https://example.com/thumb1.jpg",
      price: 19.99,
    },
    {
      id: 2,
      title: "Smooth Transition",
      thumbnailUrl: "https://example.com/thumb2.jpg",
      price: 24.99,
    },
    {
      id: 3,
      title: "Playful Icons",
      thumbnailUrl: "https://example.com/thumb3.jpg",
      price: 14.99,
    },
  ];

  return (
    <div className="min-h-screen bg-[#0a0a0a] py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-[#1a1a1a] rounded-lg shadow-lg overflow-hidden mb-10">
        <div className="px-8 py-6">
          <div className="flex items-center">
            <img
              src={designer.avatarUrl}
              alt={designer.name}
              className="w-32 h-32 rounded-full mr-6 object-cover"
            />
            <div>
              <h1 className="text-4xl font-extrabold text-[#ffffff] mb-2">
                {designer.name}
              </h1>
              <p className="text-gray-400 text-lg mb-2">{designer.location}</p>
              <p className="text-gray-300 text-lg">{designer.bio}</p>
            </div>
          </div>
        </div>
        <div className="px-8 py-6 bg-[#2a2a2a]">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <FaStar className="text-yellow-400 text-2xl mr-1" />
              <span className="text-xl font-semibold text-[#ffffff]">
                {designer.averageRating.toFixed(1)}
              </span>
              <span className="text-gray-500 text-sm ml-1">
                ({designer.totalReviews} reviews)
              </span>
            </div>
            <div className="flex items-center">
              <FaDownload className="text-blue-500 text-2xl mr-1" />
              <span className="text-xl font-semibold text-[#ffffff]">
                {designer.totalDownloads}
              </span>
              <span className="text-gray-500 text-sm ml-1">downloads</span>
            </div>
            <div className="flex items-center">
              <FaDollarSign className="text-green-500 text-2xl mr-1" />
              <span className="text-xl font-semibold text-[#ffffff]">
                {designer.totalSales}
              </span>
              <span className="text-gray-500 text-sm ml-1">sales</span>
            </div>
          </div>
        </div>
      </div>

      <h2 className="text-3xl font-extrabold text-[#ffffff] mb-6">
        Animations by {designer.name}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {animations.map((animation) => (
          <div
            key={animation.id}
            className="bg-[#1a1a1a] rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105"
          >
            <img
              src={animation.thumbnailUrl}
              alt={animation.title}
              className="w-full h-60 object-cover"
              onError={(e) =>
                (e.target.src = "https://via.placeholder.com/300")
              }
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold text-[#ffffff] mb-2">
                {animation.title}
              </h3>
              <p className="text-gray-400 text-lg">
                ${animation.price.toFixed(2)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DesignerProfilePage;
