import React, { useState, useRef, useEffect } from "react";
import { FaUser, FaCog, FaSignOutAlt } from "react-icons/fa";
import "../../styles/user_menu.css";
import { useAuth0 } from "@auth0/auth0-react";
import { LogoutButton } from "../auth/auth";
import { Link } from "react-router-dom";

const UserMenu = () => {
  const { logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (action) => {
    if (action === "signout") {
      logout({ returnTo: window.location.origin });
    }
    setIsOpen(false);
  };

  return (
    <div className="user-menu" ref={menuRef}>
      <button className="user-menu__toggle" onClick={toggleMenu}>
        <FaUser />
        <span>Account</span>
      </button>
      {isOpen && (
        <ul className={`user-menu__dropdown ${isOpen ? "show" : ""}`}>
          <li>
            <button>
              <FaUser />
              <Link to="/profile">Profile</Link>
            </button>
          </li>
          <li>
            <button onClick={() => handleMenuItemClick("settings")}>
              <FaCog />
              <span>Settings</span>
            </button>
          </li>
          <li>
            <button onClick={() => handleMenuItemClick("signout")}>
              <FaSignOutAlt />
              <LogoutButton />
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default UserMenu;
