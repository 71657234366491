import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Hero from "./components/layout/Hero";
import AnimationGrid from "./components/animations/animation_grid";
import AnimationCard from "./components/animations/animation_card";
import FAQSection from "./components/common/FAQSection";
import UserProfile from "./components/user/UserProfile";

import PricingSection from "./components/pricing/PricingSection";
import CartPage from "./components/cart/CartPage";

import WishlistSlideout from "./components/common/WishlistSlideout";
import CategoryFilter from "./components/filters/CategoryFilter";
import SortDropdown from "./components/filters/SortDropdown";
import Pagination from "./components/common/Pagination";
import CheckoutProcess from "./components/checkout/CheckoutProcess";
import LeaderboardPage from "./components/leaderboard/LeaderboardPage";

import DesignerProfile from "./components/designer-profile/DesignerProfile";
import AnimationModal from "./components/animations/animation_modal";
import UploadAnimation from "./components/marketplace/UploadAnimation";
import PurchaseHistory from "./components/marketplace/PurchaseHistory";
import DesignerDashboard from "./components/designer-profile/DesignerDashboard";
import { fetchAnimations } from "./animation_data";

const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAnimation, setSelectedAnimation] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [showWishlist, setShowWishlist] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [animations, setAnimations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortOption, setSortOption] = useState("popularity");

  const navigate = useNavigate();

  useEffect(() => {
    fetchAnimations().then((data) => setAnimations(data));
  }, []);

  const filteredAnimations = animations
    .filter((animation) =>
      animation.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(
      (animation) =>
        selectedCategory === "All" || animation.category === selectedCategory
    );

  const sortedAnimations = [...filteredAnimations].sort((a, b) => {
    if (sortOption === "price") return a.price - b.price;
    if (sortOption === "popularity") return b.downloads - a.downloads;
    return 0;
  });

  const itemsPerPage = 12;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAnimations = sortedAnimations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(sortedAnimations.length / itemsPerPage);

  const addToCart = (animation) => {
    setCartItems([...cartItems, animation]);
    navigate("/cart");
  };

  const removeFromCart = (animation) => {
    setCartItems(cartItems.filter((item) => item.id !== animation.id));
  };

  const toggleWishlist = (animation) => {
    if (wishlistItems.some((item) => item.id === animation.id)) {
      setWishlistItems(
        wishlistItems.filter((item) => item.id !== animation.id)
      );
    } else {
      setWishlistItems([...wishlistItems, animation]);
    }
  };

  const handleCheckout = () => {
    setShowCheckout(true);
  };

  const navigateToCart = () => {
    navigate("/cart");
  };

  const MainContent = () => (
    <>
      <Hero />
      <main>
        <CategoryFilter
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <SortDropdown sortOption={sortOption} setSortOption={setSortOption} />
        <section id="animations">
          <h2>Featured Animations</h2>
          <AnimationGrid
            animations={currentAnimations}
            setSelectedAnimation={setSelectedAnimation}
            addToCart={addToCart}
            toggleWishlist={toggleWishlist}
            wishlistItems={wishlistItems}
          />
        </section>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
        <PricingSection />
      </main>
    </>
  );

  return (
    <div className="app">
      <Header
        cartItemsCount={cartItems.length}
        wishlistItemsCount={wishlistItems.length}
        setShowWishlist={setShowWishlist}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        navigateToCart={navigateToCart}
      />
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/marketplace" element={<MainContent />} />
        <Route path="/designer-profile" element={<DesignerProfile />} />

        <Route
          path="/cart"
          element={
            <CartPage
              cartItems={cartItems}
              removeFromCart={removeFromCart}
              setShowCheckout={setShowCheckout}
            />
          }
        />
        <Route path="/purchase-history" element={<PurchaseHistory />} />
        <Route path="/dashboard" element={<DesignerDashboard />} />
        <Route path="/leaderboard" element={<LeaderboardPage />} />
        <Route path="/upload" element={<UploadAnimation />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/faq" element={<FAQSection />} />
      </Routes>
      <Footer />
      {selectedAnimation && (
        <AnimationModal
          animation={selectedAnimation}
          closeModal={() => setSelectedAnimation(null)}
          addToCart={addToCart}
          toggleWishlist={toggleWishlist}
          isInWishlist={wishlistItems.some(
            (item) => item.id === selectedAnimation.id
          )}
        />
      )}
      {showWishlist && (
        <WishlistSlideout
          wishlistItems={wishlistItems}
          removeFromWishlist={toggleWishlist}
          closeWishlist={() => setShowWishlist(false)}
        />
      )}
      {showCheckout && (
        <CheckoutProcess
          cartItems={cartItems}
          closeCheckout={() => setShowCheckout(false)}
        />
      )}
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
