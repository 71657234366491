import React from "react";

const WishlistSlideout = ({
  wishlistItems,
  removeFromWishlist,
  closeWishlist,
}) => {
  return (
    <div className="fixed inset-y-0 right-0 max-w-md w-full bg-[#1a1a1a] shadow-lg flex flex-col text-white">
      <div className="flex justify-between items-center p-4 border-b border-[#00c896] bg-[#0a0a0a]">
        <h2 className="text-2xl font-bold text-[#00c896]">Wishlist</h2>
        <button
          onClick={closeWishlist}
          className="text-white hover:text-[#00c896] transition-colors duration-300"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="flex-grow overflow-y-auto p-4">
        {wishlistItems.length === 0 ? (
          <p className="text-center text-gray-400">Your wishlist is empty</p>
        ) : (
          wishlistItems.map((item) => (
            <div
              key={item.id}
              className="flex items-center justify-between py-3 border-b border-[#00c896] transition-colors duration-300 hover:bg-[#0a0a0a]"
            >
              <div className="flex items-center">
                <img
                  src={item.thumbnail}
                  alt={item.name}
                  className="w-16 h-16 object-cover rounded-lg mr-4"
                />
                <div>
                  <h3 className="text-lg font-semibold">{item.name}</h3>
                  <p className="text-[#ff4d4d] text-lg">${item.price}</p>
                </div>
              </div>
              <button
                onClick={() => removeFromWishlist(item)}
                className="text-[#ff4d4d] hover:text-red-700 transition-colors duration-300"
              >
                Remove
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default WishlistSlideout;
