import React, { useRef, useEffect } from 'react';
import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';

const RiveAnimation = ({ src, alt }) => {
  const { RiveComponent, rive } = useRive({
    src: src,
    autoplay: true,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.Center,
    }),
  });

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && rive) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      rive.layout = new Layout({
        fit: Fit.Cover,
        alignment: Alignment.Center,
        minX: 0,
        minY: 0,
        maxX: width,
        maxY: height,
      });
    }
  }, [rive]);

  return (
    <div ref={containerRef} className="rive-animation-container" style={{ width: '100%', height: '200px' }}>
      {src.endsWith('.riv') ? (
        <RiveComponent />
      ) : (
        <div className="rive-placeholder" style={{ backgroundImage: `url(${src})` }}>
          <span>{alt}</span>
        </div>
      )}
    </div>
  );
};

export default RiveAnimation;