import React from 'react';

const PricingCard = ({ plan }) => {
  return (
    <div className={`pricing-item ${plan.featured ? 'featured' : ''}`}>
      <h3>{plan.name}</h3>
      <p className="price">
        {typeof plan.price === 'number' ? `$${plan.price.toFixed(2)}/month` : plan.price}
      </p>
      <ul>
        {plan.features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <button className="purchase-btn">
        {plan.name === 'Enterprise' ? 'Contact Sales' : 'Choose Plan'}
      </button>
    </div>
  );
};

export default PricingCard;