import React from 'react';
import '../../styles/feature_comparison.css';

const FeatureComparison = ({ plans }) => {
  const allFeatures = plans.reduce((acc, plan) => {
    plan.features.forEach(feature => {
      if (!acc.includes(feature)) {
        acc.push(feature);
      }
    });
    return acc;
  }, []);

  return (
    <div className="feature-comparison">
      <h2 className="feature-comparison__title">Feature Comparison</h2>
      <div className="feature-comparison__table-wrapper">
        <table className="feature-comparison__table">
          <thead>
            <tr>
              <th className="feature-column">Feature</th>
              {plans.map((plan, index) => (
                <th key={index} className="plan-column">
                  <div className="plan-name">{plan.name}</div>
                  <div className="plan-price">{plan.price}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allFeatures.map((feature, index) => (
              <tr key={index}>
                <td className="feature-column">{feature}</td>
                {plans.map((plan, planIndex) => (
                  <td key={planIndex} className="plan-column">
                    {plan.features.includes(feature) ? (
                      <span className="check">✓</span>
                    ) : (
                      <span className="dash">-</span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FeatureComparison;