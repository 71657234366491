import React from "react";

const SortDropdown = ({ sortOption, setSortOption }) => {
  return (
    <div className="flex justify-end my-4">
      <select
        value={sortOption}
        onChange={(e) => setSortOption(e.target.value)}
        className="block w-48 py-2 px-3 border border-[#00c896] bg-[#1a1a1a] text-white rounded-md shadow-sm focus:outline-none focus:ring-[#00c896] focus:border-[#00c896]"
      >
        <option value="popularity">Sort by Popularity</option>
        <option value="price">Sort by Price</option>
        <option value="newest">Sort by Newest</option>
      </select>
    </div>
  );
};

export default SortDropdown;
