import React from 'react';
import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';
import '../../styles/hero.css';
import cat from '../../assets/wa.riv';

const Hero = () => {
  const { RiveComponent: CatEyes } = useRive({
    src: cat,
    stateMachines: 'State Machine 1',
    autoplay: true,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.Center,
    }),
  });

  return (
    <section className="hero">
      <div className="hero-content">
        <h1 className="hero-title">
          <span className="gradient-text">Animate</span> Your Vision
        </h1>
        <p className="hero-subtitle">
          Discover, customize, and bring your ideas to life with Rive animations
        </p>
      </div>
      <div className="cat-eyes-container" style={{ width: '400px', height: '400px', margin: '0 auto' }}>
        <CatEyes />
      </div>
      <div className="hero-cta">
        <button className="cta-button primary">Explore Animations</button>
        <button className="cta-button secondary">How It Works</button>
      </div>
      <div className="hero-cards">
        <div className="feature-card">
          <i className="fas fa-paint-brush"></i>
          <h3>Customizable</h3>
          <p>Tailor animations to fit your brand</p>
        </div>
        <div className="feature-card">
          <i className="fas fa-bolt"></i>
          <h3>High Performance</h3>
          <p>Optimized for smooth playback</p>
        </div>
        <div className="feature-card">
          <i className="fas fa-code"></i>
          <h3>Easy Integration</h3>
          <p>Seamlessly add to your projects</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;