import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaShoppingCart,
  FaHeart,
  FaBars,
  FaTimes,
  FaUser,
} from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";
import UserMenu from "../common/user_menu";
import "../../styles/header.css";

const Header = ({
  cartItemsCount,
  wishlistItemsCount,
  setShowWishlist,
  searchTerm,
  setSearchTerm,
  navigateToCart,
}) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-[#1a1a1a] text-white py-4 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex flex-wrap items-center justify-between">
        <div className="flex items-center">
          <Link to="/" className="text-2xl font-bold text-[#00c896] mr-4">
            RiveKit
          </Link>
          <button
            className="ml-auto md:hidden text-white focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>

        <nav
          className={`${
            isMenuOpen ? "block" : "hidden"
          } md:block w-full md:w-auto mt-4 md:mt-0`}
        >
          <ul className="flex flex-col md:flex-row md:space-x-6 space-y-2 md:space-y-0">
            <li>
              <Link
                to="/"
                className="hover:text-[#00c896] block"
                onClick={() => setIsMenuOpen(false)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/marketplace"
                className="hover:text-[#00c896] block"
                onClick={() => setIsMenuOpen(false)}
              >
                Animations
              </Link>
            </li>
            <li>
              <Link
                to="/leaderboard"
                className="hover:text-[#00c896] block"
                onClick={() => setIsMenuOpen(false)}
              >
                Leaderboard
              </Link>
            </li>
            {isAuthenticated && (
              <>
                <li>
                  <Link
                    to="/upload"
                    className="hover:text-[#00c896] block"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Upload Animation
                  </Link>
                </li>
                <li>
                  <Link
                    to="/purchase-history"
                    className="hover:text-[#00c896] block"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Purchase History
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dashboard"
                    className="hover:text-[#00c896] block"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Designer Dashboard
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>

        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } md:flex items-center space-y-4 md:space-y-0 md:space-x-6 w-full md:w-auto mt-4 md:mt-0`}
        >
          <div className="relative w-full md:w-64">
            <input
              type="text"
              placeholder="Search animations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-[#0a0a0a] text-white pl-10 pr-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-[#00c896] w-full"
            />
          </div>
          <button
            onClick={() => setShowWishlist(true)}
            className="relative hover:text-[#00c896] block w-full md:w-auto text-left md:text-center"
          >
            <FaHeart size={24} className="inline-block mr-2 md:mr-0" />
            <span className="md:hidden">Wishlist</span>
            {wishlistItemsCount > 0 && (
              <span className="absolute top-0 right-0 md:-top-2 md:-right-2 bg-[#ff4d4d] text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                {wishlistItemsCount}
              </span>
            )}
          </button>
          <button
            onClick={navigateToCart}
            className="relative hover:text-[#00c896] block w-full md:w-auto text-left md:text-center"
          >
            <FaShoppingCart size={24} className="inline-block mr-2 md:mr-0" />
            <span className="md:hidden">Cart</span>
            {cartItemsCount > 0 && (
              <span className="absolute top-0 right-0 md:-top-2 md:-right-2 bg-[#ff4d4d] text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                {cartItemsCount}
              </span>
            )}
          </button>
          {isAuthenticated ? (
            <UserMenu />
          ) : (
            <button
              onClick={loginWithRedirect}
              className="bg-[#00c896] text-[#0a0a0a] px-4 py-2 rounded hover:bg-[#00a07a] transition-colors duration-300"
            >
              Login
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
