import React from 'react';
import PricingCard from './PricingCard';
import FeatureComparison from './feature_comparison';

const PricingSection = () => {
  const plans = [
    {
      name: 'Basic',
      price: 19.99,
      features: [
        'Access to 100 animations',
        'Basic customization',
        'Email support'
      ]
    },
    {
      name: 'Pro',
      price: 39.99,
      features: [
        'Access to all animations',
        'Advanced customization',
        'Priority support',
        'Custom animation requests'
      ],
      featured: true
    },
    {
      name: 'Enterprise',
      price: 'Custom',
      features: [
        'All Pro features',
        'Dedicated account manager',
        'On-premise deployment',
        'Custom integrations'
      ]
    }
  ];

  return (
    <section id="pricing">
      <h2>Pricing Plans</h2>
      <div className="pricing-grid">
        {plans.map((plan, index) => (
          <PricingCard key={index} plan={plan} />
        ))}
      </div>
      <FeatureComparison plans={plans} />
    </section>
  );
};

export default PricingSection;